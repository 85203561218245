import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './MobileCarousal.css';

function MobileCarousal({ images }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex( swiper.realIndex ); 
  };

  return (
    <div className="container-mobile">
      <Swiper
        effect={'flip'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        onSlideChange={(swiper) => handleSlideChange(swiper)}
        className="swiper_container-mobile"
      >
        {images.map((image, index) => (
          <SwiperSlide style={{ width: "360px", gap: "5px" }} key={index}>
            <img style={{ height: "400px", width: "340px", borderRadius: "0" }} src={image} alt={`slide_${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="custom-bullets">
        {Array.from({ length: images.length }, (_, index) => (
          <div
            key={index}
            className={`custom-bullet ${index === activeIndex ? 'active' : ''}`}
            onClick={() => setActiveIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default MobileCarousal;
