import React, { useState, useEffect } from "react";
import ProfileCard from "./ProfileCard/ProfileCard";
import axios from "axios";
import FiltersComponent from "./FiltersComponent";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setCardData } from '../../../redux/actions';
import "./leftprofile.css";


const LeftProfile = ({ user }) => {
  const { tailor_id } = useParams();
  
  const portfolio_id = localStorage.getItem('pid');
  const dispatch = useDispatch();

  const uniqueFilters = `https://backend.darzeeapp.com/portfolio/filters?username=${tailor_id}`;
  const getBasePortfolioSuboutift = `https://backend.darzeeapp.com/portfolio/${portfolio_id}/portfolio_outfit`;

  const [isMobileView, setIsMobileView] = useState(false);
  const [isAllActive, setIsAllActive] = useState(true);

  const [selectedOutfitTypes, setSelectedOutfitTypes] = useState([]);
  const [outfitTypes, setOutfitTypes] = useState([]);
  const [error, setError] = useState(null);

  const handleOutfitTypeChangeMobile = (outfitIndex) => {
    // console.log("workig")
    if (outfitIndex === undefined) {
      setSelectedOutfitTypes([]);
    }
    else {
      setSelectedOutfitTypes([outfitIndex]);
    }
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    axios.get(uniqueFilters)
      .then((response) => {
        // console.log(response.data);
        const outfitFilters = response.data.outfit_filter.map((item) => ({
          [item.name]: item.index,
        }));
        setOutfitTypes(outfitFilters);
      })
      .catch((error) => {
        setError(error);
      });
  }, [uniqueFilters]);

  useEffect(() => {
    if (portfolio_id) {
      // console.log("working");
      const getPortfolioSuboutift = selectedOutfitTypes.length > 0
        ? `https://backend.darzeeapp.com/portfolio/${portfolio_id}/portfolio_outfit?outfit_type=${selectedOutfitTypes.join(',')}`
        : getBasePortfolioSuboutift;
      axios.get(getPortfolioSuboutift)
        .then((response) => {
          // console.log(response.data.outfit_details);
          const outfitDetails = response.data.outfit_details;
          dispatch(setCardData(outfitDetails));
        })
        .catch((error) => {
          dispatch(setCardData([]));
          setError(error);
        });
    }
  }, [selectedOutfitTypes, dispatch, getBasePortfolioSuboutift, portfolio_id]);

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <ProfileCard user={user} />
      {isMobileView ? (
        <div className="clothfilters mobile-view">
          <hr />
          <div className="horizontal-scroll d-flex">
            <div
              className={`filter-item ${isAllActive ? 'active' : ''}`}
              onClick={() => {
                handleOutfitTypeChangeMobile();
                setIsAllActive(true);
              }}>All</div>
            {outfitTypes.map((outfitTypeMapping, index) => {
              const [outfitType, outfitTypeIndex] = Object.entries(outfitTypeMapping)[0];
              const isActive = selectedOutfitTypes.includes(outfitTypeIndex);
              return (
                <div
                  key={index}
                  className={`filter-item ${isActive ? 'active' : ''}`}
                  onClick={() => {
                    setIsAllActive(false);
                    handleOutfitTypeChangeMobile(outfitTypeIndex);
                  }}
                >
                  {outfitType}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <FiltersComponent />
      )}
    </>

  );
};

export default LeftProfile;

