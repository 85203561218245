import React from 'react'
import { useState, useEffect } from 'react';
import './Footer.css';
import darzee from '../../Assets/darzee.png';
import india from '../../Assets/india.svg';

function Footer({ user, color, textColor }) {
  // console.log(user);
  const [profileName, setProfileName] = useState("");
  const [boutique, setBoutique] = useState("");
  const [profileImage, setProfileImage] = useState("");

  useEffect(() => {
    if (user) {
      setProfileName(user.tailor_name);
      setBoutique(user.boutique_name);
      if (user.profile_image_link)
        setProfileImage(user.profile_image_link);
      else
        setProfileImage("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEbyJzoc-I0vdGIXZ55FpeOm-lJTcoFfjCumIF51-JkOERdaWe9PL33stI-yWWEdlIBM8&usqp=CAU");
  
    }
  }, [user]);

  return (
    <footer className="footer">
      <div className='footer_container'>
        <hr />

        <div className='footer_profile'>
          <img
            src={profileImage}
            className="team-img"
            alt="pic"
          />
          <h3>{profileName}</h3>
          <span className='boutique'>
            <p>{boutique}</p>
          </span>
        </div>
        <div className="footerEnd" style={{ background: color, color: textColor }}>
          <span>

            <a href='https://www.darzeeapp.com/'>
              <img
                src={darzee}
                alt="Darzee"
                className='footerEndDarzee'
              />
            </a>
          </span>
          Made in
          <span>
            <img
              src={india}
              alt="India"
              className='footerEndIndia'
            />
          </span>
          with
          <span className="footerEndIndia">❤</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
