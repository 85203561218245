// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, h1, h2, h3, p, ul, li {
	margin: 0;
	padding: 0;
  }



@media (max-width: 1120.12px) {
	.row {
		max-width: 322px;
		margin-left: auto;
		margin-right: auto;
	}
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,UAAU;EACT;;;;AAIF;CACC;EACC,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;CACnB;AACD","sourcesContent":["body, h1, h2, h3, p, ul, li {\n\tmargin: 0;\n\tpadding: 0;\n  }\n\n\n\n@media (max-width: 1120.12px) {\n\t.row {\n\t\tmax-width: 322px;\n\t\tmargin-left: auto;\n\t\tmargin-right: auto;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
