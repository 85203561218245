import "./rightprofile.css";
import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import CustomCarousel from "./customCarousel/customCarousel";

function RightProfile({ user }) {
  const navigate = useNavigate();
  const { tailor_id } = useParams();

  const [isMobileView, setIsMobileView] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAllCards, setShowAllCards] = useState({});

  const cardData = useSelector((state) => state.cardData);
  const subIndex = useSelector((state) => state.subIndex);
  // console.log(cardData);
  // show or hide sub-outfit type
  const toggleShowCards = (subOutfitType) => {
    setShowAllCards({
      ...showAllCards,
      [subOutfitType]: !showAllCards[subOutfitType],
    });
  };

  const openImageModal = (cardIndex, imageIndex) => {
    if (cardData[cardIndex] && cardData[cardIndex].portfolio_outfits[imageIndex]) {
      const images = cardData[cardIndex].portfolio_outfits[imageIndex].image_url;
      if (images) {
        setSelectedImage(images);
        setOpenModal(true);
      }
    }
  };

  const getInitialCards = (outfitType) => {
    const outfitData = cardData.find(
      (data) => data.outfit_type === outfitType
    );
    if (outfitData) {
      const cards = outfitData.portfolio_outfits;
      return cards ? cards.slice(0, 4) : [];
    }
    return [];
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //for mobile outfit view

  const handleShowAllClick = (outfitType) => {
    // console.log(outfitType);
    const subIndexName = subIndex[outfitType];
    navigate(`/${tailor_id}/${subIndexName}`);
};


  // console.log(cardData)

  return (
    <div className="rightProfile">
      {isMobileView ? (
        <div className="rightProfileItems">
          {/* mobile view  */}
          {cardData && cardData.length > 0 ? (cardData.map((data, cardIndex) => (
            <div key={data.outfit_type} className="subOutfit">
                <div className="rightProfileHeader">{data.outfit_type} 
                  <span onClick={() => handleShowAllClick(data.outfit_type_ordinal)} style={{ color: "#4D7AFF" }}>
                    Show All
                  </span>
                </div>
              <div className="card-container-mobile">
                {getInitialCards(data.outfit_type).map((card, imageIndex) => (
                  <div
                    className="card-scrollable"
                    key={imageIndex}
                    onClick={() => handleShowAllClick(data.outfit_type_ordinal)}
                  >
                    <img
                      src={card.image_url[0]}
                      alt="..."
                      className="card-img-top"
                    />
                  </div>
                ))}
              </div>

            </div>
          )))
            : (<p>No data available</p>)
          }
        </div>
      ) : (
        <div className="rightProfileItems">
          {cardData && cardData.length > 0 ? (cardData.map((data, cardIndex) => (
            <div key={data.outfit_type} className="subOutfit">
              <div className="rightProfileHeader">
                {data.outfit_type}
                <div
                  className="rightProfileHeaderContent"
                  onClick={() => toggleShowCards(data.outfit_type)}
                >
                  {showAllCards[data.outfit_type] ? "Show Less" : "Show All"}
                </div>
              </div>
              <div className="card-container">
                {showAllCards[data.outfit_type]
                  ? data.portfolio_outfits.map((card, imageIndex) => (
                    <div
                      className="card"
                      key={imageIndex}
                      onClick={() => openImageModal(cardIndex, imageIndex)}
                    >
                      <img
                        src={card.image_url[0]}
                        alt="..."
                        className="card-img-top"
                      />
                      <div className="card-body">
                        <h5 className="card-title">{card.title}</h5>
                        <p className="card-text">{card.creation_time}</p>
                      </div>
                    </div>
                  ))
                  : getInitialCards(data.outfit_type).map((card, imageIndex) => (
                    <div
                      className="card"
                      key={imageIndex}
                      onClick={() => openImageModal(cardIndex, imageIndex)}
                    >
                      <img
                        src={card.image_url[0]}
                        alt="..."
                        className="card-img-top"
                      />
                      <div className="card-body">
                        <h5 className="card-title">{card.title}</h5>
                        <p className="card-text">{card.creation_time}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )))
            : (<h6 style={{ textAlign: 'center', justifyContent: 'center' }}>No Outifts Available</h6>)
          }
        </div>
      )}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        classNames={{
          modal: 'custom-modal-class'
        }}
      >
        <CustomCarousel images={selectedImage} />
      </Modal>


    </div>
  );
}

export default RightProfile;