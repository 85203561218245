// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  .footer {
    margin-top: -100px !important;
  }
}

h3{
  padding-top:10px;
}

.footer {
  position: relative;
  bottom: 0;
  margin-bottom: -25px;
  width: 100%;
}

.boutique{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  padding-top: 5px;
}

.footer_container hr{
  width: 100%;
  height: 2px;
  background-color: #323232;
}

.footerEnd{
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  background-color: #323232;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.07038461416959763px;
}

.footer_profile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
  padding-top: 20px;
}

.footerEndDarzee{
  width: 50px;
  height: auto;
  margin-right:10px;
}

.footerEndIndia{
  width: 30px;
  height: auto;
  margin: 0 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE;IACE,6BAA6B;EAC/B;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB","sourcesContent":["@media (max-width: 768px) {\n  .footer {\n    margin-top: -100px !important;\n  }\n}\n\nh3{\n  padding-top:10px;\n}\n\n.footer {\n  position: relative;\n  bottom: 0;\n  margin-bottom: -25px;\n  width: 100%;\n}\n\n.boutique{\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 500;\n  padding-top: 5px;\n}\n\n.footer_container hr{\n  width: 100%;\n  height: 2px;\n  background-color: #323232;\n}\n\n.footerEnd{\n  display: flex;\n  height: 70px;\n  align-items: center;\n  justify-content: center;\n  background-color: #323232;\n  color: #fff;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 19px;\n  letter-spacing: 0.07038461416959763px;\n}\n\n.footer_profile{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin: 40px 0;\n  padding-top: 20px;\n}\n\n.footerEndDarzee{\n  width: 50px;\n  height: auto;\n  margin-right:10px;\n}\n\n.footerEndIndia{\n  width: 30px;\n  height: auto;\n  margin: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
