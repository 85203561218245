import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { EffectCoverflow, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './customCarousel.css';

import leftArrow from '../../../../Assets/leftArrow.png';
import rightArrow from '../../../../Assets/rightArrow.png';

function CustomCarousel({ images }) {

  return (
    <div className="container" style={{ width: "80%" }}>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={4} 
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Navigation]}
        className="swiper_container"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img style={{ borderRadius: "0" }} src={image} alt="slide_image" />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="slider-controler" style={{ position: "absolute", width: "80%", top: "300px", display: "contents" }}>
        <div
          className="swiper-button-prev slider-arrow"
          style={{ position: "absolute", left: "40px" }}
        >
          <img src={leftArrow} style={{ width: "60%" }} alt="left_arrow" />
        </div>
        <div
          className="swiper-button-next slider-arrow"
          style={{ position: "absolute", left: "auto", right: "0px" }}
        >
          <img src={rightArrow} style={{ width: "60%" }} alt="right_arrow" />
        </div>
      </div>
    </div>
  );
}

export default CustomCarousel;
