import React, { useState, useEffect } from 'react';
import './ProfileCard.css';
import wpIcon from '../../../../Assets/wp.png';
import fbIcon from '../../../../Assets/fb.png';
import igIcon from '../../../../Assets/ig.png';
import twIcon from '../../../../Assets/tw.png';


const ProfileCard = ({ user }) => {
    // console.log(user);

    const [profileName, setProfileName] = useState("");
    const [profileAbout, setProfileAbout] = useState("");
    const [boutique, setBoutique] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [socialMediaLinks, setSocialMediaLinks] = useState([]);



    useEffect(() => {
        if (user) {
            // console.log(user);
            setProfileName(user.tailor_name);
            setBoutique(user.boutique_name);
            setProfileAbout(user.portfolio_about);
            if (user.profile_image_link)
                setProfileImage(user.profile_image_link);
            else
                setProfileImage("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEbyJzoc-I0vdGIXZ55FpeOm-lJTcoFfjCumIF51-JkOERdaWe9PL33stI-yWWEdlIBM8&usqp=CAU");
            
            const socialLinks = [
                {
                    platform: "https://wa.me/+91" + user.social_media.whatsapp,
                    iconUrl: wpIcon,
                },
                {
                    platform: user.social_media.facebook,
                    iconUrl: fbIcon,
                },
                {
                    platform: user.social_media.instagram,
                    iconUrl: igIcon,
                },
                {
                    platform: user.social_media.twitter,
                    iconUrl: twIcon,
                },
            ];

            const filteredSocialLinks = socialLinks.filter(link => link.platform);
            setSocialMediaLinks(filteredSocialLinks);
        }
    }, [user]);

    

    return (
        <div className="team-item">
            <div className="team-img-container">
                <img
                    src={profileImage}
                    className="team-img"
                    alt="pic"
                />
            </div>
            <h3>{profileName}</h3>
            <div className="team-info">
                    {boutique}
                    <br />
            </div>
            <ul className="team-icon">
                {socialMediaLinks.map((link, index) => (
                    <li key={index}>
                        <a href={link.platform} target="_blank" rel="noopener noreferrer">
                            <img
                                src={link.iconUrl}
                                className={link.platform}
                                alt={link.platform}
                            />
                        </a>
                    </li>
                ))}
            </ul>
            <span className="para">
                <p>{profileAbout}</p>
            </span>
        </div>
    )
}

export default ProfileCard;