// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  .bodyContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    left: 0 !important;
    margin: 0 !important;
    padding: 10px 5px 10px 5px !important;
  }

  .leftBodyContainer {
    width: 100% !important;
    height: auto !important;
    top: -120px;
    margin-right: 0;
    margin-left: 0 !important;
    padding: 10px !important;
  }
  .rightBodyContainer {
    position: relative;
    top: -120px;
    width: 100% !important;
    height:auto !important;
    padding: 10px 0px 10px 5px !important;
    margin-right: 0;
    margin-left: 0 !important;
  }
}

.root-container{
  height: 100%;
}

.bodyContainer {
  position: relative;
  height: auto; 
  display: flex;
  left: 20px;
  margin: 20px;
  padding: 10px;
}

.leftBodyContainer {
  position: relative;
  width: 25%;
  padding: 10px 30px;
  margin-right: 10px; 
  top: -120px;
}

.rightBodyContainer {
  width: 75%;
  padding: 10px;
  margin-left: 28px; 
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainBody/MainBody.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;IACpB,qCAAqC;EACvC;;EAEA;IACE,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,yBAAyB;IACzB,wBAAwB;EAC1B;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,sBAAsB;IACtB,sBAAsB;IACtB,qCAAqC;IACrC,eAAe;IACf,yBAAyB;EAC3B;AACF;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,UAAU;EACV,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["@media (max-width: 768px) {\n  .bodyContainer {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    width: 100%;\n    height: auto;\n    left: 0 !important;\n    margin: 0 !important;\n    padding: 10px 5px 10px 5px !important;\n  }\n\n  .leftBodyContainer {\n    width: 100% !important;\n    height: auto !important;\n    top: -120px;\n    margin-right: 0;\n    margin-left: 0 !important;\n    padding: 10px !important;\n  }\n  .rightBodyContainer {\n    position: relative;\n    top: -120px;\n    width: 100% !important;\n    height:auto !important;\n    padding: 10px 0px 10px 5px !important;\n    margin-right: 0;\n    margin-left: 0 !important;\n  }\n}\n\n.root-container{\n  height: 100%;\n}\n\n.bodyContainer {\n  position: relative;\n  height: auto; \n  display: flex;\n  left: 20px;\n  margin: 20px;\n  padding: 10px;\n}\n\n.leftBodyContainer {\n  position: relative;\n  width: 25%;\n  padding: 10px 30px;\n  margin-right: 10px; \n  top: -120px;\n}\n\n.rightBodyContainer {\n  width: 75%;\n  padding: 10px;\n  margin-left: 28px; \n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
