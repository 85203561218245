
export const setCover = (data) => {
    return {
        type: 'SET_COVER_DATA',
        payload: data,
    };
};

export const setCardData = (data) => {
    return {
        type: 'SET_CARD_DATA',
        payload: data,
    };
};

export const setSelectedOutfitData = (outfitType) => {
    return {
        type: 'SET_OUTFIT_TYPE',
        payload: outfitType,
    };
};

export const setSelectedSubOutfitData = (suboutfitType) => {
    return {
        type: 'SET_SUB_OUTFIT_TYPE',
        payload: suboutfitType,
    };
};

export const setSelectedColorData = (color) => {
    return {
        type: 'SET_COLOR_TYPE',
        payload: color,
    };
};

export const setSubIndex = (subIndex) => {
    return {
        type: 'SET_SUB_INDEX',
        payload: subIndex,
    };
};