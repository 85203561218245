import React from 'react'
import darzeeicon from "../../Assets/darzee.png";

export const Rootdir = () => {
  return (
    <>
      <h5>Welcome to darzeeapp's portfolio</h5>
      <img src={darzeeicon} alt="darzeeicon" />
    </>
  )
}
