import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { setCardData, setSelectedOutfitData, setSelectedSubOutfitData, setSelectedColorData, setSubIndex } from '../../../redux/actions';
import "./leftprofile.css";
import axios from "axios";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";

function FiltersComponent({onApply}) {
  const dispatch = useDispatch();
  const { tailor_id } = useParams();
  const portfolio_id = localStorage.getItem('pid');

  const uniqueFilters = `https://backend.darzeeapp.com/portfolio/filters?username=${tailor_id}`;
  const getBasePortfolioSuboutift = `https://backend.darzeeapp.com/portfolio/${portfolio_id}/portfolio_outfit`;

  const [outfitTypes, setOutfitTypes] = useState([]);
  const [subOutfitData, setSubOutfitData] = useState([]);
  const [subOutfitTypes, setSubOutfitTypes] = useState([]);
  const [color, setColor] = useState([]);

  const [selectedOutfitTypes, setSelectedOutfitTypes] = useState([]);
  const [selectedSubOutfitTypes, setSelectedSubOutfitTypes] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);

  const [isOutfitTypesCollapsed, setIsOutfitTypesCollapsed] = useState(false);
  const [isSubOutfitTypesCollapsed, setIsSubOutfitTypesCollapsed] = useState(false);
  const [isColorFiltersCollapsed, setIsColorFiltersCollapsed] = useState(false);

  const handleOutfitTypeChange = (outfitIndex) => {
    if (selectedOutfitTypes.includes(outfitIndex)) {
      setSelectedOutfitTypes(selectedOutfitTypes.filter((type) => type !== outfitIndex));
      setSelectedSubOutfitTypes([]);
    } else {
      setSelectedOutfitTypes([...selectedOutfitTypes, outfitIndex]);
    }
  };


  const handleCheckboxChange = (value) => {
    if (selectedSubOutfitTypes.includes(value)) {
      setSelectedSubOutfitTypes(selectedSubOutfitTypes.filter((prev) => prev !== value));
    } else {
      setSelectedSubOutfitTypes([...selectedSubOutfitTypes, value]);
    }
  };

  const handleColorChange = (colorIndex) => {
    if (selectedColors.includes(colorIndex)) {
      setSelectedColors(selectedColors.filter((color) => color !== colorIndex));
    } else {
      setSelectedColors([...selectedColors, colorIndex]);
    }
    // console.log(selectedColors);
  };


  const [flag, setFlag] = useState(false);

  const handleApply = () => {
    setFlag(!flag);
    if(onApply){
      onApply();
    }
    dispatch(setSelectedOutfitData(selectedOutfitTypes));
    dispatch(setSelectedSubOutfitData(selectedSubOutfitTypes));
    dispatch(setSelectedColorData(selectedColors));
  }


  const handleOutfitTypesClick = () => {
    setIsOutfitTypesCollapsed(!isOutfitTypesCollapsed);
  };

  const handleSubOutfitTypesClick = () => {
    setIsSubOutfitTypesCollapsed(!isSubOutfitTypesCollapsed);
  };
  const toggleColorFilters = () => {
    setIsColorFiltersCollapsed(!isColorFiltersCollapsed);
  };

  useEffect(() => {
    axios.get(uniqueFilters)
      .then((response) => {
        // console.log(response.data);
        if(response.data){
            const outfitFilters = response.data.outfit_filter.map((item) => ({
              [item.name]: item.index,
            }));
            const subOutfitFitlers = response.data.outfit_filter.map((item) => ({
              [item.index]: item.sub_outfits,
            }));
            const subIndex = response.data.outfit_filter.reduce((acc,item) => {
              acc[item.index] = item.sub_index_name;
              return acc;
            },{});
            
            dispatch(setSubIndex(subIndex));
            setOutfitTypes(outfitFilters);
            setSubOutfitData(subOutfitFitlers);
            setColor(response.data.color_filter);
          }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [uniqueFilters]);

  useEffect(() => {
    if (selectedOutfitTypes.length > 0) {
      const selectedSubOutfit = [];

      selectedOutfitTypes.forEach((outfitTypeIndex) => {
        const subOutfits = subOutfitData.find((data) => data[outfitTypeIndex]);

        if (subOutfits) {
          const subOutfitData = subOutfits[outfitTypeIndex];

          Object.keys(subOutfitData).forEach((subOutfitTypeIndex) => {
            selectedSubOutfit.push({
              [subOutfitData[subOutfitTypeIndex]]: subOutfitTypeIndex,
            });
          });
        }
      });
      setSubOutfitTypes(selectedSubOutfit);
    } else {
      setSubOutfitTypes([]);
    }
  }, [selectedOutfitTypes]);

  //for desktop view
  useEffect(() => {
    if(portfolio_id){
      
    const getPortfolioSuboutift = selectedOutfitTypes.length > 0 || selectedSubOutfitTypes.length > 0 || selectedColors.length > 0
      ? `https://backend.darzeeapp.com/portfolio/${portfolio_id}/portfolio_outfit?outfit_type=${selectedOutfitTypes.join(',')}&sub_outfit=${selectedSubOutfitTypes.join(',')}&color=${selectedColors.join(',')}`
      : getBasePortfolioSuboutift;
    axios.get(getPortfolioSuboutift)
      .then((response) => {
        const outfitDetails = response.data.outfit_details;
        dispatch(setCardData(outfitDetails));
      })
      .catch((error) => {
        dispatch(setCardData([]));
        console.log("No outfits found for the filters");
        console.error("Error fetching data:", error);
      });
    }
  }, [flag, dispatch, getBasePortfolioSuboutift]);


  return (
    <div className="clothfilters">
      <h5>Filters</h5>
      <div id="clothfiltersOptions" className="mt-4 mb-4">
        <h6 className="d-flex" onClick={handleOutfitTypesClick}>
          <span className="filters-title">Outfit types{" "}</span>
          <div className="filters-title-collapse" >
            {isOutfitTypesCollapsed ? <BsCaretUpFill /> : <BsCaretDownFill />}
          </div>
        </h6>
        <div
          className={`columns d-flex ${isOutfitTypesCollapsed ? "is-collapsed" : ""
            }`}
        >
          <div className="column">
            {outfitTypes.slice(0, Math.ceil(outfitTypes.length / 2)).map((outfitTypeMapping, index) => {
              const [outfitType, outfitTypeIndex] = Object.entries(outfitTypeMapping)[0];
              return (
                <label key={index} className="checkbox-label">
                  <input
                    type="checkbox"
                    onChange={() => handleOutfitTypeChange(outfitTypeIndex)}
                  />
                  {outfitType}
                </label>
              );
            })}
          </div>
          <div className="column">
            {outfitTypes.slice(Math.ceil(outfitTypes.length / 2)).map((outfitTypeMapping, index) => {
              const [outfitType, outfitTypeIndex] = Object.entries(outfitTypeMapping)[0];
              return (
                <label key={index} className="checkbox-label">
                  <input
                    type="checkbox"
                    onChange={() => handleOutfitTypeChange(outfitTypeIndex)}
                  />
                  {outfitType}
                </label>
              );
            })}
          </div>
        </div>
      </div>
      {selectedOutfitTypes.length > 0 && (
        <div className="mt-4 mb-4">
          <h6 className="d-flex" onClick={handleSubOutfitTypesClick}>
            <span className="filters-title">Sub Outfit types{" "}</span>
            <div className="filters-title-collapse" style={{width:"10%"}}>
              {isSubOutfitTypesCollapsed ? <BsCaretUpFill /> : <BsCaretDownFill />}
            </div>
          </h6>
          <div
            className={`columns d-flex ${isSubOutfitTypesCollapsed ? "is-collapsed" : ""
              }`}
          >
            <div className="column">
              {subOutfitTypes.slice(0, Math.ceil(subOutfitTypes.length / 2)).map((subOutfitTypeMapping, index) => {
                const [subOutfitType, subOutfitTypeIndex] = Object.entries(subOutfitTypeMapping)[0];
                return (
                  <label key={index} className="checkbox-label">
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(subOutfitTypeIndex)}
                    />
                    {subOutfitType}
                  </label>
                );
              })}
            </div>
            <div className="column">
              {subOutfitTypes.slice(Math.ceil(subOutfitTypes.length / 2)).map((subOutfitTypeMapping, index) => {
                const [subOutfitType, subOutfitTypeIndex] = Object.entries(subOutfitTypeMapping)[0];
                return (
                  <label key={index} className="checkbox-label">
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(subOutfitTypeIndex)}
                    />
                    {subOutfitType}
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {selectedSubOutfitTypes.length > 0 && (
        <div id="colorfiltersOptions">
          <h6 className="d-flex" onClick={toggleColorFilters}>
            <span className="filters-title">Color filters{" "}</span>
            <div className="filters-title-collapse">{isColorFiltersCollapsed ? <BsCaretUpFill /> : <BsCaretDownFill />}</div>
          </h6>
          <div className={`mt-4 mb-4 ${isColorFiltersCollapsed ? 'is-collapsed' : ''}`}>
            {!isColorFiltersCollapsed && (
              <div className="color-circle-container">
                {Object.entries(color).map(([colorIndex, colorName]) => (
                  <div
                    key={colorIndex}
                    className={`color-circle ${selectedColors.includes(colorIndex) ? 'selected' : ''}`}
                    style={{ background: `#${colorIndex}` }}
                    onClick={() => handleColorChange(colorIndex)}
                  >

                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="d-grid filters-apply">
        <button className="btn btn-primary" style={{ background: "#4D7AFF" }} onClick={handleApply}>Apply</button>
      </div>
    </div>
  );
}

export default FiltersComponent;
