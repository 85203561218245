import React from "react";
import "./Header.css";
import { useSelector } from "react-redux";

const Header = () => {
  const imgURL = useSelector((state) => state.cover);;
  // console.log(imgURL);
  return (
    <header className="header">
      <div className="header_container">
        <img
          src={imgURL}
          alt="Header"
        />
      </div>
    </header>
  );
};

export default Header;
