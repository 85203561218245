// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_container {
  width: 100%;
  height: 200px; 
  position: relative;
  overflow: hidden;
}

.header_container img {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  object-fit: cover;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":[".header_container {\n  width: 100%;\n  height: 200px; \n  position: relative;\n  overflow: hidden;\n}\n\n.header_container img {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  right: 0;\n  top: 0;\n  object-fit: cover;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
