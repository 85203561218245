// MobileOutfitView.js
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import './MobileOutfitView.css';
import Footer from '../../../Footer/Footer';
import MobileCarousal from './MobileCarousel/MobileCarousal';
import filters from "../../../../Assets/filters.png"
import close from "../../../../Assets/close.png"
import FiltersComponent from '../../LeftContainer/FiltersComponent';
import axios from "axios";

function MobileOutfitView() {
  const { tailor_id, subIndexName } = useParams();
  const baseURL = `https://backend.darzeeapp.com/portfolio/?username=${tailor_id}`;

  const subIndex = useSelector((state) => state.subIndex);
  const outfitType = Object.keys(subIndex).find(key => subIndex[key] === subIndexName);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const toggleFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };
  const [user, setUser] = useState(null);
  const subOutfitData = useSelector((state) => state.SubOutfitType);
  const outfitData = useSelector((state) => state.OutfitType);
  
  const portfolio_id = localStorage.getItem('pid');
  const colorData = useSelector((state) => state.colorType);

  const [displayedCard, setDisplayedCard] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(baseURL);
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    fetchData();
  }, [baseURL]);

  useEffect(() => {
    async function fetchPortfolioData() {
      if (portfolio_id) {
        try {
          let outfitDetails = [];
          if(outfitData.length === 0 && subOutfitData.length === 0 && colorData === "") {
            outfitDetails = JSON.parse(localStorage.getItem('outfitData'));
            // console.log(outfitDetails);
          }
          else{
            // console.log("working");
          const getPortfolioSuboutift =
            outfitData.length > 0 || subOutfitData.length > 0
              ? `https://backend.darzeeapp.com/portfolio/${portfolio_id}/portfolio_outfit?outfit_type=${outfitData.join(',')}&sub_outfit=${subOutfitData.join(',')}&color=${colorData}`
              : `https://backend.darzeeapp.com/portfolio/${portfolio_id}/portfolio_outfit?outfit_type=${outfitType}`;
          
            const response = await axios.get(getPortfolioSuboutift);
            outfitDetails = response.data.outfit_details;
          }
          setDisplayedCard(outfitDetails);
          
          localStorage.setItem('outfitData', JSON.stringify(outfitDetails));
        } catch (error) {
          setDisplayedCard([]);
          setError(error);
        }
      }
    }

    fetchPortfolioData();
  }, [outfitData, subOutfitData, portfolio_id, outfitType, colorData]);


  return (
    <div className="custom-modal-class-mobile">
    <div className='mobile-outfit-container'>
      <h6 className='d-flex mobileOutfit-title'>
        Outfits
        <img src={filters} onClick={toggleFilters} alt="filters" />
      </h6>

      {isFiltersVisible
        &&
        <div className='FiltersComponent'>
          <div className="FiltersComponentClose">
            <img onClick={toggleFilters} src={close} alt="close" />
          </div>
          <div className='FiltersComponent-content'>
            <FiltersComponent onApply={toggleFilters} />
          </div>
        </div>
      }

      <div className="mobile-outfit-view">
        {displayedCard && displayedCard.length > 0 ? (
          displayedCard.map((data, index) => (
            <div key={index} style={{ width: "100%"}}>
              <div key={`outfit-type-${index}`}>{data.outfit_type}</div>
              {data.portfolio_outfits.map((card, imageIndex) => (
                <div className="card-mobileoutfit" key={`card-${index}-${imageIndex}`}>
                  <div className="card-details-mobileoutfit">
                    <div className='card-mobileoutfit-title'>{card.title}</div>
                    <div className='card-mobileoutfit-date'>Published on: {card.creation_time}</div>
                  </div>
                  <MobileCarousal images={card.image_url} />
                </div>
              ))}
            </div>
          ))
        ) : (
          <h6 key="no-outfits">No Outfits Available</h6>
        )}


      </div>
      <div className="mobile-outfit-view-footer">
        <Footer user={user} color={"white"} textColor={"#32343AB2"} />
      </div>
    </div>
    </div>
  );

}

export default MobileOutfitView;
