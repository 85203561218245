// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container-mobile {
    max-width: 400px;
    height:578px;
    margin: 0;
    padding: 0;
}
  
   
  .swiper_container-mobile {
    position: relative; 
  }
  
  
  @media (max-width: 768px) {
    .swiper_container {
      height: 500px;
    }
  }
  

  .swiper-pagination-bullet-active {
    background-color: white !important;
    opacity:100% !important;
    z-index: 100; 
  }
  .swiper-pagination{
    margin: 25px -5px;
    z-index: 1000000;
  }
  .custom-bullets {
    top:20px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }
  
  .custom-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    opacity: 0.5;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
  }
  
  .custom-bullet.active {
    opacity: 1 !important;
    background-color: #fff;
  }`, "",{"version":3,"sources":["webpack://./src/components/MainBody/RightContainer/MobileOutfitView/MobileCarousel/MobileCarousal.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,SAAS;IACT,UAAU;AACd;;;EAGE;IACE,kBAAkB;EACpB;;;EAGA;IACE;MACE,aAAa;IACf;EACF;;;EAGA;IACE,kCAAkC;IAClC,uBAAuB;IACvB,YAAY;EACd;EACA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;EACA;IACE,QAAQ;IACR,kBAAkB;IAClB,SAAS;IACT,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,sCAAsC;;EAExC;;EAEA;IACE,qBAAqB;IACrB,sBAAsB;EACxB","sourcesContent":["\n.container-mobile {\n    max-width: 400px;\n    height:578px;\n    margin: 0;\n    padding: 0;\n}\n  \n   \n  .swiper_container-mobile {\n    position: relative; \n  }\n  \n  \n  @media (max-width: 768px) {\n    .swiper_container {\n      height: 500px;\n    }\n  }\n  \n\n  .swiper-pagination-bullet-active {\n    background-color: white !important;\n    opacity:100% !important;\n    z-index: 100; \n  }\n  .swiper-pagination{\n    margin: 25px -5px;\n    z-index: 1000000;\n  }\n  .custom-bullets {\n    top:20px;\n    position: relative;\n    left: 50%;\n    transform: translateX(-50%);\n    display: flex;\n    justify-content: center;\n  }\n  \n  .custom-bullet {\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background-color: #ccc;\n    opacity: 0.5;\n    margin: 0 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  \n  }\n  \n  .custom-bullet.active {\n    opacity: 1 !important;\n    background-color: #fff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
