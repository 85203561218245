import "./App.css";
import MainBody from './components/MainBody/MainBody';
import MobileOutfitView from "./components/MainBody/RightContainer/MobileOutfitView/MobileOutfitView";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Rootdir } from "./components/RootDirectory/Rootdir";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Rootdir />} />
        <Route path="/:tailor_id" element={<MainBody />} />
        <Route path="/:tailor_id/:subIndexName" element={<MobileOutfitView />} />
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;
